/** @jsx jsx */
import "bootstrap/dist/css/bootstrap.min.css";
import React, { FC } from "react";;
import { Box, jsx } from "theme-ui";
import TopMenu, { TopMenuStyle } from "../../../components/top-menus/top-menu";
import Container from "../../container/container";
import Header from "./header/header";
import styles from "./primary.style";
import "../../../css/style.css";

export interface Coordinates {
  latitude?: number;
  longitude?: number;
}

const PrimaryLayout: React.FunctionComponent<{
  fluid?: boolean;
  paddingX?: string;
  bgColor?: string;
  homeLink?: string;
  pathPrefix?: string;
  showBanner?: boolean;
  topMenuStyle?: TopMenuStyle;
  topMenuChild?: FC<any>;
  showBackground?: boolean;
  showMap?: boolean;
  mapCoordinates?: Coordinates;
  showScrollUpButton?: Boolean;
}> = ({
  fluid,
  paddingX,
  bgColor,
  homeLink,
  pathPrefix,
  showBanner,
  topMenuStyle,
  topMenuChild,
  showBackground,
  showMap,
  mapCoordinates,
  children,
}) => {
  return (
    <Box
      as="main"
      sx={{
        backgroundColor: bgColor ? bgColor : "transparent",
        ...styles.main,
      }}
      className={`${showBackground ? "section-background" : ""}`}
    >
      <Header fluid={false} homeLink={homeLink} pathPrefix={pathPrefix} />

      {topMenuChild && (
        <TopMenu topMenuStyle={topMenuStyle}>{topMenuChild({})}</TopMenu>
      )}

      <Container fluid={fluid} paddingX={paddingX}>
        {children}
      </Container>
    </Box>
  );
};

export default PrimaryLayout;
