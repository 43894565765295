/** @jsx jsx */
import { graphql, Link, StaticQuery } from "gatsby";
import { Image, jsx } from "theme-ui";
import styles from "./logo.style";
import { GatsbyImage } from "gatsby-plugin-image"
import { getJwtToken, getJwtTokenRoles } from "../../services/auth-service";

type PropsType = {
  imgSrc?: string;
  imgAlt?: string;
  path?: string;
};

const logoStaticQuery = graphql`
  {
    file(relativePath: { eq: "Placenta-Plus-Logo-all-silver.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

const Logo: React.FC<PropsType> = ({ imgSrc, imgAlt, path = "/" }) => {
  return (
    <StaticQuery<GatsbyTypes.Query>
      query={`${logoStaticQuery}`}
      render={({ file }) => {
        const logo = file.childImageSharp.gatsbyImageData;
        const valid = getJwtToken();
        return (
          <div sx={styles.wrapper} className="logo">
            <Link to={!valid ? '/' : '/account'}>
              <GatsbyImage image={logo} sx={styles.logo} alt={"logo"} />
            </Link>
          </div>
        );
      }}
    />
  );
};

export default Logo;
