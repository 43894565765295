const styles = {
	topbar: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		minHeight: 80,
		borderBottomWidth: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: 'muted',
		backgroundColor: 'white',
		'@media only screen and (min-width: 1024px)': {
			'.container.fluid': {
				'.grid': {
					gridTemplateColumns: '1.5fr',
					'.topbar-links': {
						marginLeft: 'auto',
					},
				},
			},
		},
		'@media only screen and (max-width: 1080px)': {
			borderBottom: 0,
			boxShadow: '0 2px 4px rgba(41,41,41,0.06)',
		},
		'.logo a': {
			position: 'relative',
			marginTop:'20px',
			marginBottom: '20px',
			'@media only screen and (max-width: 1080px)': {
				marginLeft: '10px',
				marginRight: '0',
			},
		},
	},
	grid: {
		alignItems: 'center',
		gridTemplateColumns: '1.5fr',
	},
	hamburgBtn: {
		display: 'none',
		'@media only screen and (max-width: 1080px)': {
			display: 'inline-flex',
		},
		'@media only screen and (max-width: 768px)': {
			position: 'absolute',
			right: '10px',
			top: '-8px'
		},
	},
	container: {
		'@media only screen and (max-width: 768px)': {
			maxWidth: '100%!important',
			margin: '0!important'
		},
	},
	search: {
		'@media only screen and (max-width: 590px)': {
			display: 'none'
		},
	}
};

export default styles;
