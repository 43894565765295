import axios from "axios";
import {
  removeJwtTokens,
  setJwtTokens,
  validateJwtToken,
} from "./auth-service";
import { SendError } from "./error-log-service";
import { navigate } from "gatsby";

/**
 * @description Logs user in and sets JWT cookies
 * @returns {String} If error API response message, success sets JwtToken cookies and logs the user in
 */
export const Login = async (data: any) => {
  return await axios.post(`user/login/`, data).then(function (response: any) {
      setJwtTokens('token', response.data.token, 120);
      setJwtTokens('refresh-token', response.data.refreshToken, 120);
      if (validateJwtToken('token')) {
          navigate('/account');
      }      
  }).catch(function (error: any) {
      SendError(error);
      return error.response?.data.message;
  });
}

/**
 * @description Register user and sends email confirmation
 * @returns {String} If API response message success it sets JwtToken cookies and logs the user in
 */
export const Register = async (data: any) => {
  return await axios
    .post(`user/register/`, data)
    .then(function (response: any) {
      setJwtTokens("token", response.data.token, 120);
      setJwtTokens("refresh-token", response.data.refreshToken, 120);
      if (validateJwtToken("token")) window.location.href = "/account";
    })
    .catch(function (error: any) {
      SendError(error);
      return error.response?.data.message;
    });
};

/**
 * @description Update authenticated user email address
 * @returns {String} API response message
 */
export const UpdateUserEmail = async (data: any) => {
  return await axios
    .post(`user/email/`, data)
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (error: any) {
      return error.response?.data.message;
    });
};

/**
 * @description Update authenticated user password
 * @returns {String} API response message
 */
export const UpdateUserPassword = async (data: any) => {
  return await axios
    .post(`user/password/`, data)
    .then(function (response: any) {
      return response.data;
    })
    .catch(function (error: any) {
      return error.response?.data.message;
    });
};

/**
 * @param {number} id
 * @description Log authenticated user out by user id remove cookie tokens
 */
export const Logout = async (id: any) => {
  return await axios.put(`user/logout/${id}`).then(function (response: any) {
    if (response.data !== 0) {
      removeJwtTokens("/");
    }
  });
};

/**
 * @param {String} email
 * @description Send reset email password to geniune user holders
 */
export const RequestAccountResetPassword = async (data: any) => {
  return await axios
    .post(`user/requestpassword/`, data)
    .then(function (response: any) {
      return response;
    });
};

/**
 * @description Reset accont holder password
 */
export const ResetAccountResetPassword = async (data: any) => {
  return await axios
    .post(`user/resetpassword/`, data)
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      return error.response;
    });
};

/**
 * @description Get authenticated user details by user Id
 * @returns {any} object of user
 */
export const GetUserDetails = async (id: any) => {
  return await axios
    .get(`user/${id}`)
    .then(function (response: any) {
      return response;
    })
    .catch(function (error: any) {
      return error;
    });
};


/**
 * @description Get list of users 
 * @returns {any} object of user
 */
 export const GetList = async (offset: number, fetch: number, searchTerm: string) => {
  let config = {};

  if (offset !== null && fetch !== null) {
      config = {
          params: {
              offset: offset,
              fetch: fetch,
              searchTerm: !searchTerm ? "" : searchTerm
          }
      }
  }

  return await axios.get(`user/list`, config).then(function (response: any) {
      return response;
  }).catch(function (error: any) {
      return error.response?.data.message;
  });
}

export const GetUserCount = async () => {
  return await axios.get(`user/count`).then(function (response: any) {
      return response;
  }).catch(function (error: any) {
      return error.response?.data.message;
  });
}

/**
 * @description Register admin user
 */
export const RegisterAdmin = async (data: any) => {
  return await axios
    .post(`user/register/admin`, data).then(function (response: any) {
      return response.data;
    })
    .catch(function (error: any) {
      SendError(error);
      return error.response?.data.message;
    });
};

/**
   * @description Get authenticated user
   * @returns {String} user entity
 */
 export const GetUser = async (id: any) => {
  return await axios.get(`user/${id}`).then(function (response: any) {
      return response;
  }).catch(function (error: any) {
     return error;
  });
}


/**
   * @description Schedules user user for deletion and removes cookie tokens
 */
 export const DeleteAccount = async (id: any) => {
  return await axios.put(`user/delete/admin/${id}`).then(function (response: any) {
    return response;
  });
}