const styles = {
	header: {
		backgroundColor: 'white',
		'@media only screen and (max-width: 991px)': {
			boxShadow: '0 2px 4px rgba(41, 41, 41, 0.06)',
		},
		nav: {
			'.logo': {
				opacity: 0,
				visibility: 'hidden',
				transform: 'translate3d(0, -20px, 0)',
				width: 0,
				img: {
					width: 90,
				},
			},
			'.mainMenu': {
				transition: 'padding 0.25s ease',
			},
		},
		'.sticky-outer-wrapper.active': {
			nav: {
				'li > a': {
					color: '#685f52'
				},
				'li > svg': {
					color: '#685f52'
				},
				background: 'white',
				'.logo': {
					opacity: 1,
					visibility: 'visible',
					width: 'auto',
					transform: 'translate3d(0, 0, 0)',
				},
				'.logo img': {
					width: '130px',
					position: 'absolute',
					top: '5px'
				},
			},
		},
	},
	showOnlyDesktop: {
		display: 'none',
		'@media only screen and (min-width: 1081px)': {
			display: 'block',
			'&.isSearchedActive.isNoticeActive': {
				'.outibleSearch': {
					'.searchResult': {
						top: 115,
						height: 'calc(100vh - 115px)',
					},
				},
			},
		},
	},
	showOnlyTab: {
		display: 'none',
		'@media only screen and (max-width: 1080px)': {
			display: 'block',
		},
	},
};

export default styles;
