/** @jsx jsx */
import Sticky from 'react-stickynode';
import { Box, jsx } from 'theme-ui';
import styles from './header.style';
import Topbar from './topbar/topbar';

const Header: React.FC<{
	fluid?: boolean;
	homeLink?: string;
	showNoticeBar?: boolean;
	pathPrefix?: string;
}> = ({ fluid, homeLink, pathPrefix }) => {

	const addClass = [];

	return (
		<Box as="header" sx={styles.header}>
			<Box sx={styles.showOnlyDesktop} className={addClass.join(' ')}>
				<Topbar fluid={fluid} homeLink={homeLink} />
			</Box>
			<Box sx={styles.showOnlyTab}>
				<Sticky activeClass="active" innerZ={9}>
					<Topbar fluid={fluid} homeLink={homeLink} />
				</Sticky>
			</Box>
		</Box>
	);
};

export default Header;
