/** @jsx jsx */
import { Box, jsx } from 'theme-ui';

const Wrapper = ({ ...props }) => {
	return (
		<Box
			sx={{
				width: '100%',
				marginLeft: 'auto',
				marginRight: 'auto',
				paddingX: props.paddingX ?? '20px',
				'@media only screen and (min-width: 1200px) and (max-width: 1760px)': {
					paddingX: props.paddingX ? props.paddingX : `${props.fluid ? '30px' : '20px'}`,
				},
			}}
			{...props}
		/>
	)
}

export default Wrapper;
