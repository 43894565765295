/** @jsx jsx */
import { Link } from "gatsby";
import {  useRef } from "react";
import { Box, jsx } from "theme-ui";
import { getJwtToken, getJwtTokenIdentifier } from "../../../../../services/auth-service";
import { Logout } from "../../../../../services/user-service";
import styles from "./navbar.style";

// const MainMenu: React.FC<{onClick?: () => void; showAccountControl: boolean;}> = ({ onClick, showAccountControl }) => (
const MainMenu = (props: any) => {
  const mainMenu = useRef(null);

  const token = getJwtToken();
  return (
    <Box as="ul" ref={mainMenu} sx={styles.mainMenu} className="mainMenu">

      <Box as="li" sx={styles.mainMenuItem}>
        <Link
          activeClassName="active"
          to={`/`}
          onClick={props.onClick}
        >
          Home
        </Link>
      </Box>

      <Box as="li" sx={styles.mainMenuItem}>
        <Link
          activeClassName="active"
          to={`/about-us`}
          onClick={props.onClick}
        >
          Store
        </Link>
      </Box>

      <Box as="li" sx={styles.mainMenuItem}>
        <Link
          activeClassName="active"
          to={`/faq`}
          onClick={props.onClick}
        >
          Contact
        </Link>
      </Box>

      {props.showAccountControl && (
        <Box as="span" style={{ width: "100%" }}>
          <hr className="bar-indent" />

          {!token ? (
            <Box
              as="li"
              key={" "}
              sx={styles.mainMenuItem}
              style={{ fontWeight: 600 }}
            >
              <Link activeClassName="active" to="/" onClick={props.onClick}>
                Login
              </Link>
            </Box>
          ) : (
            <Box
              as="li"
              key={" "}
              sx={styles.mainMenuItem}
              style={{ fontWeight: 600 }}
            >
              <Link activeClassName="active" to="/account">
                My Account
              </Link>
              <button
                sx={styles.logoutDrawer}
                className="btn-none"
                onClick={logout}
                onKeyDown={logout}
              >
                Sign out
              </button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

async function logout() {
  await Logout(getJwtTokenIdentifier());
}

export default MainMenu;
