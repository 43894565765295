/** @jsx jsx */
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import { Fragment, useState } from "react";
import {  IoIosMenu } from "react-icons/io";
import { Box, Button, Flex, Grid, jsx, Text } from "theme-ui";
import Logo from "../../../../logo/logo";
import MainMenu from "../navbar/main-menu";
import TopMenu from "../navbar/top-menu";
import styles from "./topbar.style";

const Topbar: React.FC<{ fluid?: boolean; homeLink?: string }> = ({
  homeLink,
}) => {
  const [visible, setVisible] = useState(false);
  const [cartVisible, setCartVisible] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);

  const handleClose = () => {
    setClearSearch(true);
    setVisible(false);
  };

  return (
    <Fragment>
      <TopMenu />
      <Box sx={styles.topbar}>
        <div sx={styles.container} className="container">
          <Grid className="grid" sx={styles.grid}>
            <Flex>
              <Button
                variant="text"
                sx={styles.hamburgBtn}
                onClick={() => setVisible(true)}
                ariaLabel="Hamburg menu"
              >
                <IoIosMenu />
              </Button>

              <Logo path={homeLink} />
            </Flex>

          </Grid>
        </div>

        <Drawer
          level={null}
          width="100%"
          open={visible}
          handler={false}
          placement="left"
        >
         
          <MainMenu
            onClick={() => setVisible(false)}
            showAccountControl={true}
          />
        </Drawer>
      </Box>
    </Fragment>
  );
};

export default Topbar;
