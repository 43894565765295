const styles = {
	topMenu: {	
		backgroundColor: '#F5F5F5',
		minHeight: 63,
		textAlign: 'center',
		lineHeight: '63px',
		listStyle: 'none',
		'@media only screen and (max-width: 485px)': {
			overflowX:'scroll',
		
		},
	},
	topMenuChild: {
		fontSize: '14px',
		width: 'max-content'
	}
};

export default styles;
