/** @jsx jsx */
import { Link } from "gatsby";
import React from "react";
import { FaRegUser } from "react-icons/fa";
import { Box, jsx } from "theme-ui";
import {
  getJwtToken,
  getJwtTokenIdentifier,
} from "../../../../../services/auth-service";
import { Logout } from "../../../../../services/user-service";
import styles from "./navbar.style";

const TopMenu: React.FC<any> = () => {
  const token = getJwtToken();
  let pathname = "";

  if (typeof window !== `undefined`) {
    pathname = window?.location?.pathname.replace("/", "");
  }
  return (
    <Box as="ul" sx={styles.topMenu}>
      <Box sx={styles.rightPart}>
        <div sx={styles.topBarContainer} className="container">
          <div className="row">
            <div sx={styles.notice} className="col-md-6">
              <span sx={styles.topLeftMenu}>
                <ul sx={styles.accountControl}>
                  <li>
                    <a
                      href="https://test.placentaplus.co.uk"
                      style={{ marginLeft: 0 }}
                      target="_blank"
                    >
                      {token === null ? (
                        <React.Fragment>Online Store</React.Fragment>
                      ) : (
                        <React.Fragment>Open Store</React.Fragment>
                      )}
                    </a>
                  </li>
                  {token === null && (
                    <React.Fragment>
                      |
                      <li>
                        <a
                          href="https://test.placentaplus.co.uk/contact"
                          target="_blank"
                        >
                          Contact Us
                        </a>
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </span>
            </div>

            <div className="col-md-6">
              <span sx={styles.account}>
                {token !== null && (
                  <React.Fragment>
                    <FaRegUser sx={styles.account.icon} />
                    <ul sx={styles.accountControl}>
                      <li>
                        <Link to="/account">My Account</Link>
                      </li>
                      |
                      <li>
                        <button
                          sx={styles.logout}
                          className="btn-none"
                          onClick={logout}
                          onKeyDown={logout}
                        >
                          Sign out
                        </button>
                      </li>
                    </ul>
                  </React.Fragment>
                )}
              </span>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

async function logout() {
  await Logout(getJwtTokenIdentifier());
}

export default TopMenu;
