const styles = {
  navbar: {
    display: "flex",
    width: "100%",
    minHeight: 60,
    // boxShadow: '0 2px 4px rgba(41, 41, 41, 0.06)',
    ".container": {
      display: "flex",
      alignItems: "center",
    },
    backgroundColor: "#F5F5F5",
    borderBottom: "1px solid #e6e6e6",
  },
  rightPart: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    a: {
      display: "flex",
      color: "#685f52",
      textDecoration: "none",
      marginLeft: 15,
      transition: "color 0.2s",
      svg: {
        width: 18,
        height: 18,
      },
      ":hover": {
        color: "#00d4c5",
        textDecoration: "underline",
      },
    },
    button: {
      svg: {
        width: 17,
        height: 17,
      },
    },
  },
  search: {
    width: 420,
    "@media only screen and (max-width: 1200px)": {
      width: 520,
    },
    height: 10,
  },
  mainMenu: {
    display: "inline-flex",
    position: "absolute",
    left: "50%",
    transform: "translatex(-50%)",
    padding: 0,
    "@media only screen and (max-width: 1080px)": {
      marginX: 0,
      width: "100%",
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "30px",
    },
    li: {
      color: "#685f52",
      listStyle: "none",
      "a, span": {
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        textDecoration: "none",
        transition: "color 0.2s ease",
        "@media only screen and (max-width: 1080px)": {
          color: "#685f52",
        },
      },
    },
  },
  mainMenuItem: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginX: 16,
    "@media only screen and (max-width: 1080px)": {
      width: "100%",
      marginX: 0,
      alignItems: "flex-start",
      flexDirection: "column",
    },
    "> a, > span": {
      color: "#685f52",
      fontSize: 1,
      minHeight: [50, 60],
      "&.active": {
        fontWeight: "600",
        color: "#00d4c5",
      },
    },
    "> svg": {
      marginX: "5px",
      "@media only screen and (max-width: 1080px)": {
        position: "absolute",
        top: "16px",
        right: 0,
        color: "#685f52",
        fontSize: "16px",
      },
    },
    ":hover": {
      color: "#00d4c5",
      "> a, > span": {
        color: "#00d4c5",
        "@media only screen and (max-width: 1080px)": {
          color: "#685f52",
        },
        "&.active": {
          fontWeight: "600",
        },
      },
      ul: {
        opacity: 1,
        visibility: "visible",
        "@media only screen and (max-width: 1080px)": {
          opacity: 0,
          visibility: "hidden",
          "&.active": {
            opacity: 1,
            visibility: "visible",
            color: "#685f52",
          },
        },
        li: {
          color: "white",
          marginY: 0,
        },
      },
    },
    "&.active": {
      ul: {
        opacity: 1,
        visibility: "visible",
        height: "max-content",
        li: {
          marginY: 0,
        },
      },
    },
  },
  submenu: {
    opacity: 0,
    visibility: "hidden",
    width: 260,
    position: "absolute",
    backgroundColor: "white",
    left: 0,
    paddingTop: 9,
    paddingBottom: 12,
    paddingX: 0,
    top: "100%",
    borderTopWidth: 2,
    borderTopStyle: "solid",
    borderTopColor: "text",
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    boxShadow: "0 2px 4px rgba(41, 41, 41, 0.06)",
    transition: "opacity 0.2s ease",
    zIndex: 1,
    "@media only screen and (max-width: 1080px)": {
      width: "100%",
      borderTop: 0,
      borderRadius: 0,
      borderLeftWidth: 3,
      borderLeftStyle: "solid",
      borderLeftColor: "#70ad58",
      boxShadow: "none",
      position: "inherit",
      padding: 0,
      height: 0,
      top: 0,
    },
    li: {
      marginY: -1,
      transition: "margin 0.2s ease",
      a: {
        color: "primary",
        paddingY: 10,
        paddingX: 20,
        fontSize: "14px",
        position: "relative",
        "@media only screen and (min-width: 1081px)": {
          "&:hover": {
            color: "#00d4c5",
            textDecoration: "underline",
          },
          "&.active": {
            color: "#00d4c5",
            fontWeight: "600",
          },
        },
        "@media only screen and (max-width: 1080px)": {
          "&:hover, &.active": {
            color: "#00d4c5",
            fontWeight: "600",
          },
        },
      },
    },
  },
  right: {
    float: "left",
  },
  indent1e: {
    textIndent: "1em",
  },
  delivery: {
    float: "right",
    display: "flex",
    position: "absolute",
    right: "0",
    paddingRight: "40px",
  },
  topLeftMenu: {
    a: {
      fontSize: "14px",
      lineHeight: "32px",
      color: "#656766",
    },
    lineHeight: "32px",
    display: "flex",
  },
  account: {
    display: "flex",
    float: "right",
    lineHeight: "32px",
    margin: 0,
    a: {
      fontSize: "14px",
      lineHeight: "32px",
      color: "#656766",
    },
    icon: {
      padding: "8px",
      width: "35px!important",
      height: "35px!important",
      color: "#685f52",
      marginTop: "-2px",
    },
    ".top-contact": {
      "@media only screen and (max-width: 992px)": {
        display: "none",
      },
    },
    "@media only screen and (max-width: 768px)": {
      display: "none",
    },
  },
  topMenu: {
    top: -1,
    backgroundColor: "#F5F5F5",
    height: 32,
    "@media only screen and (max-width: 768px)": {
      padding: "0 22px!important",
    },
  },
  notice: {
    padding: 0,
    message: {
      margin: 0,
      lineHeight: "32px",
      fontSize: "14px",
      fontWeight: 400,
      icon: {
        padding: "6px",
        backgroundColor: "#D9E8DB",
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        marginTop: "-4px",
        marginRight: "10px",
        "@media only screen and (max-width: 400px)": {
          display: "none",
        },
      },
      "@media only screen and (max-width: 400px)": {
        fontSize: "12px",
      },
    },
  },
  grid: {
    gridGap: 0,
  },
  logout: {
    marginRight: 10,
    color: "#656766",
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    paddingTop: 0,
  },
  logoutDrawer: {
    color: "#685f52",
    fontSize: "15px",
    padding: 0,
  },
  accountControl: {
    display: "contents",
    listStyle: "none",
    a: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  topBarContainer: {
    "@media only screen and (max-width: 768px)": {
      maxWidth: "100%!important",
      margin: "0!important",
    },
  },
};

export default styles;
